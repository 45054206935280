<script setup lang="ts">
import { toClipboard } from '@soerenmartius/vue3-clipboard'

const props = defineProps<{
  payMessage?: any
  payTotal?: any
  payBank?: any
  currency?: any
}>()

const { t, n, locale } = useI18n()

const sitedata: any = useSiteData()

const data = computed(() => (sitedata.value?.bank_info === 2 && props?.payBank?.bank_account_number) ? props?.payBank : sitedata.value?.payment)

const bankQrcode = computed(() => _get(data.value, `bank_qrcode${props?.currency ? `_${_lowerCase(props?.currency)}` : ''}`))
const bankName = computed(() => _get(data.value, `bank_name${props?.currency ? `_${_lowerCase(props?.currency)}` : ''}`))
const bankAccountName = computed(() => _get(data.value, `bank_account_name${props?.currency ? `_${_lowerCase(props?.currency)}` : ''}`))
const bankAccountNumber = computed(() => _get(data.value, `bank_account_number${props?.currency ? `_${_lowerCase(props?.currency)}` : ''}`))
const bankSwiftcode = computed(() => _get(data.value, `bank_swiftcode${props?.currency ? `_${_lowerCase(props?.currency)}` : ''}`))

const currencyKey = getCurrencyKey(locale.value, props?.currency, true)
</script>

<template>
  <div v-if="data && (bankAccountNumber || bankQrcode)" class="shadow border border-dotted rounded-lg">
    <div class="block sm:flex items-center sm:justify-start p-2">
      <div v-if="bankQrcode && checkImage(bankQrcode)" class="p-2.5">
        <NuxtImg
          loading="lazy"
          :src="bankQrcode"
          class="mx-auto h-auto w-[240px] object-cover"
        />
      </div>

      <div class="text-xl text-center sm:text-left p-2.5 w-auto">
        <div class="text-3xl font-bold mb-2">
          {{ t('bank_transfer_information') }}
        </div>
        <div v-if="bankName" class="mb-2">
          {{ bankName }}
        </div>
        <div v-if="bankAccountName" class="mb-2">
          {{ bankAccountName }}
        </div>
        <div v-if="bankAccountNumber" class="block mb-2">
          <div class="mb-1 font-medium">
            {{ t('bank_account') }}:
            <span class="px-3 cursor-pointer" @click="toClipboard(bankAccountNumber)">
              {{ bankAccountNumber }}
            </span>
          </div>
        </div>
        <div v-if="props?.payTotal" class="block mb-2">
          <div class="mb-1 font-medium">
            {{ t('amount') }}:
            <span class="px-3 cursor-pointer" @click="toClipboard(`${props?.payTotal}`)">
              {{ n((props?.payTotal ?? 0) * 1, currencyKey ? 'currency' : 'decimal', currencyKey) }}
            </span>
          </div>
        </div>
        <div v-if="props?.payMessage" class="block mb-2">
          <div class="mb-1 font-medium">
            {{ t('content') }}:
            <span class="uppercase px-3 cursor-pointer w-full overflow-x-auto block sm:inline" @click="toClipboard(props?.payMessage)">
              {{ props?.payMessage }}
            </span>
          </div>
        </div>
        <div v-if="bankSwiftcode" class="block mb-2">
          <div class="mb-1 font-medium">
            {{ t('bank_swiftcode') }}:
            <span class="uppercase px-3 cursor-pointer" @click="toClipboard(bankSwiftcode)">
              {{ bankSwiftcode }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
